@import url("https://fonts.googleapis.com/css2?family=Niramit:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
  font-size: 1rem;
  overflow-y: hidden;
  overflow-y: scroll;
  scroll-padding-top: 250px;
  --gold: #a48559;
  --grey: #f5f6f5;
  --black: #141414;
  --dark-grey: #7472729c;
  scroll-behavior: smooth;
}
body a {
  font-size: inherit;
  font-family: Montserrat;
  font-weight: inherit;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: inherit;
}
body p {
  word-wrap: break-word;
  white-space: pre-line;
}
#book {
  scroll-snap-align: start;
  scroll-snap-stop: normal;
  scroll-snap-type: y mandatory;
}
